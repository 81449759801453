.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, 0);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  font-family: 'Gotham', sans-serif;
  width: min-content;
  /* height: 400px; */
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}
.modalBackground-certificate {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, 0);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container-certificate {
  font-family: 'Gotham', sans-serif;
  /* width: 50vw !important; */
  /* height: 75vh; */
  border-radius: 12px;
  background-color: white;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin: 1%;
}
.popup-header-certificate {
  position: relative;
  box-sizing: border-box;
  border-radius: 15px;
  justify-items: center;
}
.popup-image-certificate {
  height: 60vh;
  left: 50%;
}
.certi-cont {
  margin-top: 2%;
  display: flex;
  justify-content: center;
}
.certi-name {
  position: absolute;
  top: 45%;
  color: #bd8e33;
  top: 43%;
  font-size: 5vh;
  font-weight: 800;
  font-family: 'Dancing Script', cursive;
}
.share {
  height: 6vh;
}
.certificate-canvas {
  height: 60vh;
  width: auto;
}

.share-div-icon {
  margin-top: 10%;
  margin-bottom: 10%;
  display: flex;
  justify-content: center;
}
.modalContainer-share {
  font-family: 'Gotham', sans-serif;
  width: min-content;
  height: auto;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;

  position: fixed;
  top: 0;
  margin: 20% 1% 0% 1%;
  padding: 20px;
  z-index: 1;
}
.popupText-share {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  margin-top: 5%;
  font-size: large;
  text-align: center;
  color: black;
}

@media (max-width: 970px) {
  .certi-name {
    font-size: 3vh !important;
  }
  .popup-image-certificate {
    height: auto !important;
  }
  .certificate-canvas {
    height: auto !important;
    width: 85vw;
  }
  .modalBackground-certificate {
    width: 100vw;
    height: 70vh;
    background-color: rgba(200, 200, 200, 0);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-container-certificate {
    padding: 16px;
    margin: 0% 1%;
  }
}
