.SearchBox{
    width:100px;
    height:50px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    top:0;
    left:0;
    flex-direction: column;
    padding: 25px;
}